import React from 'react';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '30px',
    paddingBottom: '30px'
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    color: '#083F88',
    flexBasis: '100%',
    flexShrink: 0,
    fontFamily: 'Poppins'
  },
  hrLine: {
    width: '100%',
    borderBottom: '1px solid #00AAE4',
    marginBottom: '-10px',
    marginLeft: '-20px',
    [theme.breakpoints.down('sm')]: {
      borderBottom: '2px solid #00AAE4',
      marginLeft: '0px'
    }
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(14),
    color: '#083F88',
    fontFamily: 'Poppins'
  },
  lastChildAccordionDetails: {
    borderTop: '1px solid #00AAE4',
    padding: '0px 1rem',
  },
  MuiAccordionRounded: {
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    marginBottom: '10px'

  },
}));
const FAQWrapper = styled.div`
.MuiAccordion-root {
  position: relative;
  padding: 0px 3rem;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.MuiAccordionDetails-root{
  // border-top: 1px solid #00AAE4;
  padding: 0px 1rem;
}
.MuiPaper-elevation1{
  box-shadow: none;
}
.MuiAccordion-root:before{
  height: 0px;
}

@media(max-width:767px) {
  .MuiAccordion-root {
    position: relative;
    padding: 0px 0rem;
}

`;

export default function ControlledAccordions(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let accordionData;
  if (props.data) {
    accordionData = props.data.map((ele, index) => {
      let expandedData = 'panel' + index
      let aria = 'panel' + index + "bh-content";
      let aria_id = "panel" + index + "bh-header";
      const isLastChild = index === props.data.length - 1;
      return (
        <Accordion key={index} expanded={expanded === expandedData} onChange={handleChange(expandedData)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={aria}
            id={aria_id}
          >

            <Typography className={classes.heading}><b>{parse(ele.header)}</b>
              {!isLastChild && <hr className={classes.hrLine} />}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={isLastChild ? classes.lastChildAccordionDetails : null}>

            <Typography className={classes.secondaryHeading}>
              <p className={classes.secondaryHeading}>{parse(ele.data)}</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
      )
    })
  }

  return (
    <div className={classes.root}>
      <FAQWrapper>
        {accordionData}
      </FAQWrapper>
    </div>
  );
}
